<template>
  <chemical-isotope
    :symbol="symbol"
    :mass-number="massNumber"
    v-bind="allAttrs"
    :style="style"
    v-on="$listeners"
  />
</template>

<script>
import ChemicalIsotope from '@/tasks/components/displayers/ChemicalIsotope';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import {useRandomNumberBackgroundStyle} from '@/tasks/components/composables/useRandomNumberBackgroundStyle';

export default {
  name: 'ChemicalIsotopeValue',
  components: {LatexNumber, ChemicalIsotope},
  props: {
    value: {
      type: Object,
      required: true,
    },
    atomicNumber: Boolean,
  },
  setup() {
    return useRandomNumberBackgroundStyle();
  },
  computed: {
    allAttrs() {
      const result = {...this.$attrs};
      if (this.atomicNumber) {
        result.atomicNumber = this.atomicNumber_;
      }
      return result;
    },
    symbol() {
      return this.value.symbol;
    },
    massNumber() {
      return this.value.massNumber;
    },
    atomicNumber_() {
      return this.value.atomicNumber;
    },
  },
};
</script>
