<template>
  <v-container>
    <v-form @submit.prevent="submitResponse">
      <div>
        <p class="mb-3">
          1 a) Complete the following table for the indicated nuclide of the first 5 Noble gases
          (Column 18/8A elements). For the ratio, please express this in decimal form to 3 decimal
          places. Your Teaching Assistant (TA) will review your answers for this question. Please
          allow up to two days for your TA to review and potentially adjust your grade.
        </p>

        <v-simple-table class="mb-5 pl-4">
          <thead>
            <tr>
              <td></td>
              <td style="align-content: center">
                <chemical-isotope-value :value="isotope1" />
              </td>
              <td style="align-content: center">
                <chemical-isotope-value :value="isotope2" />
              </td>
              <td style="align-content: center">
                <chemical-isotope-value :value="isotope3" />
              </td>
              <td style="align-content: center">
                <chemical-isotope-value :value="isotope4" />
              </td>
              <td style="align-content: center">
                <chemical-isotope-value :value="isotope5" />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Number of protons</td>
              <td v-for="question in protonQuestions" :key="question">
                <v-text-field v-model="inputs[question]"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>Number of neutrons</td>
              <td v-for="question in neutronQuestions" :key="question">
                <v-text-field v-model="inputs[question]"></v-text-field>
              </td>
            </tr>
            <tr>
              <td>Ratio of protons to neutrons</td>
              <td v-for="question in ratioQuestions" :key="question">
                <v-text-field v-model="inputs[question]"></v-text-field>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <p class="mb-4">
          1 b) Describe any trend you discover in the way this ratio changes with increasing atomic
          number atomic number.
        </p>
        <s-textarea v-model="inputs['trendDescription']" outlined class="mb-4"></s-textarea>

        <p>
          2) Suppose the following data were collected by an experimenter who was observing the
          distance traveled by various gasses in a set amount of time through a horizontal glass
          tube at room temperature and pressure. [17 marks]
        </p>

        <v-simple-table class="mb-6" style="max-width: 300px">
          <thead>
            <tr>
              <td>Gas</td>
              <td>Distance (to the nearest cm)</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="question in questionTwoTable" :key="question">
              <td>
                <chemical-latex :content="question.gas" />
              </td>
              <td>
                <stemble-latex :content="question.distance" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <div
          v-for="question in observationQuestions"
          :key="question.inputId"
          class="mt-3 mb-4 pl-3"
        >
          <v-row class="mb-4">
            <stemble-latex :content="question.text" />
          </v-row>
          <s-textarea v-model="inputs[question.inputId]" outlined class="mb-4" />
        </div>

        <p class="mb-3">
          3) Three grams of gas are placed in a sealed 10-L flask maintained at 0°c. The molecular
          weight of the gas is 20 amu. Which of the following would most likely occur if the valve
          to the flask is opened?
        </p>

        <p class="mb-8">
          Answer each of the five statements as True or False. Explain each of your answers. You
          will be graded based on your choice (True or False) and on the correctness or
          appropriateness of your explanations. You may use calculations, common sense, examples,
          analogies, etc., for your explanation. [18 marks]
        </p>

        <div v-for="question in trueFalseQuestions" :key="question" class="mt-3 pl-3">
          <v-row class="mb-2">
            <stemble-latex :content="question.option" />
          </v-row>
          <s-textarea v-model="inputs[question.inputId]" outlined class="mb-5"></s-textarea>
        </div>
      </div>
    </v-form>
    <ai-loading-overlay v-if="isSubmitting" />
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';
import ChemicalIsotopeValue from '@/tasks/components/ChemicalIsotopeValue.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'OleMissPreLab11Grader',
  components: {
    ChemicalLatex,
    ChemicalIsotopeValue,
    AiLoadingOverlay,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        numProtons1: null,
        numProtons2: null,
        numProtons3: null,
        numProtons4: null,
        numProtons5: null,
        numNeutrons1: null,
        numNeutrons2: null,
        numNeutrons3: null,
        numNeutrons4: null,
        numNeutrons5: null,
        protonNeutronRatio1: null,
        protonNeutronRatio2: null,
        protonNeutronRatio3: null,
        protonNeutronRatio4: null,
        protonNeutronRatio5: null,
        trendDescription: null,
        observationPartA: null,
        observationPartB: null,
        observationPartC: null,
        trueFalseA: null,
        trueFalseB: null,
        trueFalseC: null,
        trueFalseD: null,
        trueFalseE: null,
      },
      protonQuestions: ['numProtons1', 'numProtons2', 'numProtons3', 'numProtons4', 'numProtons5'],
      neutronQuestions: [
        'numNeutrons1',
        'numNeutrons2',
        'numNeutrons3',
        'numNeutrons4',
        'numNeutrons5',
      ],
      ratioQuestions: [
        'protonNeutronRatio1',
        'protonNeutronRatio2',
        'protonNeutronRatio3',
        'protonNeutronRatio4',
        'protonNeutronRatio5',
      ],
      questionTwoTable: [
        {
          gas: 'SO2',
          distance: '$50$',
        },
        {
          gas: 'HCl',
          distance: '$66$',
        },
        {
          gas: 'CH4',
          distance: '$100$',
        },
        {
          gas: 'SO3',
          distance: '$44$',
        },
      ],
      observationQuestions: [
        {
          text: 'a) State your observations regarding this data as briefly and to the point as possible.',
          inputId: 'observationPartA',
        },
        {
          text: 'b) Design a hypothesis to explain your observation.',
          inputId: 'observationPartB',
        },
        {
          text: 'c) Suggest and experiment to test your hypothesis.',
          inputId: 'observationPartC',
        },
      ],
      trueFalseQuestions: [
        {
          option: 'a) The gas and oxygen in the air will mix and react.',
          inputId: 'trueFalseA',
        },
        {
          option: 'b) The gas will rush out of the flask.',
          inputId: 'trueFalseB',
        },
        {
          option: 'c) At 0°C, the 4 g of gas will still occupy 10 liters.',
          inputId: 'trueFalseC',
        },
        {
          option: 'd) Air will rush into the flask.',
          inputId: 'trueFalseD',
        },
        {
          option: 'e) The gas and oxygen will remain separate and not mix.',
          inputId: 'trueFalseE',
        },
      ],
    };
  },
  computed: {
    isotope1() {
      return this.taskState.getValueBySymbol('isotope1').content;
    },
    isotope2() {
      return this.taskState.getValueBySymbol('isotope2').content;
    },
    isotope3() {
      return this.taskState.getValueBySymbol('isotope3').content;
    },
    isotope4() {
      return this.taskState.getValueBySymbol('isotope4').content;
    },
    isotope5() {
      return this.taskState.getValueBySymbol('isotope5').content;
    },
  },
};
</script>

<style scoped></style>
