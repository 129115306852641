




import ChemicalLatex from './ChemicalLatex.vue';
import Vue, {PropType} from 'vue';

export default Vue.extend({
  name: 'ChemicalIsotope',
  components: {ChemicalLatex},
  props: {
    symbol: {
      type: String as PropType<string>,
      required: true,
    },
    massNumber: {
      type: [String, Number] as PropType<string | number | null>,
      default: null,
    },
    atomicNumber: {
      type: [String, Number] as PropType<string | number | null>,
      default: null,
    },
    charge: {
      type: [String, Number] as PropType<string | number | null>,
      default: null,
    },
  },
  computed: {
    computedValue(): string {
      let result = '';
      if (this.massNumber !== null) {
        result += `^{${this.massNumber}}`;
      }
      if (this.atomicNumber !== null) {
        result += `_{${this.atomicNumber}}`;
      }

      result += this.symbol;

      if (this.charge !== null) {
        result += `^{${this.charge}}`;
      }
      return result;
    },
  },
});
